import Vue from 'vue';
import TYPES from '@/types';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import GetNotifySpecialContributionQuery
  from '@/modules/my-investment/allianz/special-contribution/application/queries/get-notify-special-contribution-query';

// Domain
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class SpecialDepositInformationViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.GET_NOTIFY_SPECIAL_CONTRIBUTION_QUERY)
  private readonly get_notify_special_contribution_query!: GetNotifySpecialContributionQuery;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_account_not_exists = `Error customer_id:${this.customer_id}, not exists`;

  readonly view: Vue;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.special-deposit-information';

  readonly LINK_SPECIAL_CONTRIBUTION_DOCUMENT = `${process.env.VUE_APP_CANVA_URL}/design/DAFiEDnjTvk/OqQJ5aQAAeqSXQDpABFdbg/view?utm_content=DAFiEDnjTvk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#1`;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  bank_information_title = [
    this.translate('bank_information_titles.0'),
    this.translate('bank_information_titles.1'),
    this.translate('bank_information_titles.2'),
  ]

  bank_account_information = [
    {
      bank: this.translate('bank_1'),
      account: this.translate('bank_account_1'),
      place: this.translate('bank_place_1'),
      clabe: this.translate('bank_clabe_1'),
    },
    {
      bank: this.translate('bank_2'),
      account: this.translate('bank_account_2'),
      place: this.translate('bank_place_2'),
      clabe: this.translate('bank_clabe_2'),
    },
  ];

  reference_number = '';

  public constructor(view: Vue) {
    this.view = view;
  }

  initialize = async () => {
    await this.loadAllianzAccount();
    await this.notifyThatUserIsInThisFlow();
  }

  getCustomClass = (index: number) => (index === 3 ? 'pl-0 pr-0' : '');

  loadAllianzAccount = async () => {
    try {
      const allianz_account_entity = await this.get_allianz_account_query
        .execute({ customer_id: this.customer_id });
      if (allianz_account_entity) {
        this.reference_number = allianz_account_entity.policy_number.toString();
      }
    } catch (error) {
      if (error.message !== this.error_allianz_account_not_exists) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
      }
    }
  }

  notifyThatUserIsInThisFlow = async () => {
    try {
      await this.get_notify_special_contribution_query.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_notify_special_contribution_query'));
    }
  }

  cancel() {
    this.view.$emit('endProcess');
  }

  nextStep() {
    this.view.$emit('nextStep');
  }
}

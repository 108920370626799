




































































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import SpecialDepositInformationViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/special-deposits/special-deposit-information-view-model';

@Component({ name: 'SpecialDepositInformation' })
export default class SpecialDepositInformation extends Vue {
  special_deposit_info_view_model = Vue.observable(
    new SpecialDepositInformationViewModel(this),
  );

  async mounted() {
    await this.special_deposit_info_view_model.initialize();
  }
}
